import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Link, graphql } from 'gatsby';
import _ from 'lodash';
import styled from '@emotion/styled';
import AboutAuthor from '../AboutAuthor';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 80%;
  /*padding: 160px 1.0875rem 1.45rem;*/
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default ({
  data: {
    allAuthorYaml: { edges: authorNodes },
  },
}) => {
  const classes = useStyles();
  return (
    <Wrapper>
      <div className={classes.root}>
        <Grid container>
          {authorNodes.map(({ node: author }, index) => (
            <Grid item xs={12} key={`author-${author.id}`}>
              <AboutAuthor author={author} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Wrapper>
  );
};

export const pageQuery = graphql`
  query AuthorsQuery {
    allAuthorYaml {
      edges {
        node {
          id
          bio
          ar
          twitter
        }
      }
    }
  }
`;
